<template>
  <b-modal
    id="modal-manager"
    ref="refModal"
    :title="t('Người dùng')"
    size="lg"
    :ok-title="isSubmitting ? t('Đang lưu') : t('Lưu')"
    :ok-disabled="isSubmitting"
    :cancel-title="t('Huỷ')"
    cancel-variant="outline-secondary"
    :hide-header-close="true"
    no-close-on-backdrop
    @show="loadData"
    @hidden="resetModal"
    @ok="onSubmit"
  >
    <!-- Body -->
    <validation-observer
      v-if="itemLocal"
      #default="{ handleSubmit }"
      ref="refFormObserver"
    >
      <b-alert
        v-if="itemLocal.id && itemLocal.id > 0"
        show
        variant="primary"
      >
        <div class="alert-body">
          <feather-icon
            class="mr-25"
            icon="StarIcon"
          />
          <span class="ml-25">{{ t('Bạn đang cập nhật thông tin của tài khoản') }}: <strong>{{ itemLocal.user.name }}</strong></span>
        </div>
      </b-alert>
      <b-alert
        v-else
        show
        variant="danger"
      >
        <div class="alert-body">
          <feather-icon
            class="mr-25"
            icon="StarIcon"
          />
          <span class="ml-25">LƯU Ý: Trong trường hợp SĐT nhân viên mà bạn tạo đã được đăng ký tài khoản trước đó thì nhân viên sẽ đăng nhập bằng mật khẩu cũ mà không phải mật khẩu bạn khởi tạo</span>
        </div>
      </b-alert>
      <b-form
        v-if="item"
        @submit.prevent="handleSubmit(onSubmit)"
        @reset.prevent="resetForm"
      >
        <b-row v-if="!itemLocal.id || itemLocal.id === 0">
          <b-col md="4">
            <validation-provider
              #default="validationContext"
              name="name"
              rules="required"
            >
              <b-form-group
                :label="t('Họ tên')"
              >
                <template
                  v-slot:label
                >
                  {{ t('Họ tên') }}
                  <span
                    class="text-danger"
                  >
                    (*)
                  </span>
                </template>
                <b-form-input
                  id="name"
                  v-model="itemLocal.name"
                  :disabled="itemLocal.id && itemLocal.id > 0"
                  placeholder="Nguyễn Văn A"
                  trim
                  :state="getValidationState(validationContext)"
                />
                <small class="text-danger">{{
                  validationContext.errors[0]
                }}</small>
              </b-form-group>
            </validation-provider>
          </b-col>
          <b-col md="4">
            <validation-provider
              #default="validationContext"
              name="phone"
              rules="required"
            >
              <b-form-group
                :label="t('Số điện thoại')"
              >
                <template
                  v-slot:label
                >
                  {{ t('Số điện thoại') }}
                  <span
                    class="text-danger"
                  >
                    (*)
                  </span>
                </template>
                <b-form-input
                  id="phone"
                  v-model="itemLocal.phone"
                  :disabled="itemLocal.id && itemLocal.id > 0"
                  placeholder="0868987355"
                  trim
                  autocomplete="off"
                  :state="getValidationState(validationContext)"
                />
                <small class="text-danger">{{
                  validationContext.errors[0]
                }}</small>
              </b-form-group>
            </validation-provider>
          </b-col>

          <b-col md="4">
            <b-form-group
              label="Email"
            >
              <b-form-input
                id="email"
                v-model="itemLocal.email"
                :disabled="itemLocal.id && itemLocal.id > 0"
                autocomplete="off"
                placeholder="phongnn@resident.vn"
                trim
              />
            </b-form-group>
          </b-col>
        </b-row>
        <b-row>
          <b-col :md="!itemLocal.id || itemLocal.id <= 0 ? 4: 4">
            <validation-provider
              #default="validationContext"
              name="role"
              rules="required"
            >
              <select-role
                v-model="itemLocal.role"
                :default-value="itemLocal.role"
                required
                :label="t('Loại người dùng')"
                :state="getValidationState(validationContext)"
                :error="validationContext.errors[0]"
              />
            </validation-provider>
          </b-col>
          <b-col md="4">
            <validation-provider
              #default="validationContext"
              name="department"
              :rules="isTingTong ? 'required' : ''"
            >
              <b-form-group
                :label="t('Bộ phận')"
              >
                <template
                  v-slot:label
                >
                  {{ t('Bộ phận') }}
                  <span
                    v-if="isTingTong"
                    class="text-danger"
                  >
                    (*)
                  </span>
                </template>
                <b-form-input
                  id="department"
                  v-model="itemLocal.department"
                  placeholder="Kế toán"
                  trim
                  :state="getValidationState(validationContext)"
                />
                <small class="text-danger">{{
                  validationContext.errors[0]
                }}</small>
              </b-form-group>
            </validation-provider>
          </b-col>
          <b-col md="4">
            <validation-provider
              #default="validationContext"
              name="title"
              :rules="isTingTong ? 'required' : ''"
            >
              <b-form-group
                :label="t('Chức danh')"
              >
                <template
                  v-slot:label
                >
                  {{ t('Chức danh') }}
                  <span
                    v-if="isTingTong"
                    class="text-danger"
                  >
                    (*)
                  </span>
                </template>
                <b-form-input
                  id="title"
                  v-model="itemLocal.title"
                  placeholder="Nhân viên"
                  trim
                  :state="getValidationState(validationContext)"
                />
                <small class="text-danger">{{
                  validationContext.errors[0]
                }}</small>
              </b-form-group>
            </validation-provider>
          </b-col>
          <b-col
            v-if="!itemLocal.id || itemLocal.id <= 0"
            md="6"
          >
            <b-form-group
              :label="t('Mật khẩu')"
            >
              <template
                v-slot:label
              >
                {{ t('Mật khẩu') }}
                <span
                  class="text-danger"
                >
                  (*)
                </span>
              </template>
              <validation-provider
                #default="{ errors }"
                name="Mật khẩu"
                vid="password"
                rules="required|password"
              >
                <b-form-input
                  v-model="itemLocal.password"
                  type="password"
                  :state="errors.length > 0 ? false : null"
                  :placeholder="t('Mật khẩu')"
                  autocomplete="off"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col
            v-if="!itemLocal.id || itemLocal.id <= 0"
            md="6"
          >
            <b-form-group label="Xác nhận mật khẩu">
              <template
                v-slot:label
              >
                {{ t('Xác nhận mật khẩu') }}
                <span
                  class="text-danger"
                >
                  (*)
                </span>
              </template>
              <validation-provider
                #default="{ errors }"
                name="Xác nhận mật khẩu"
                rules="required|confirmed:password"
              >
                <b-form-input
                  v-model="itemLocal.passwordConfirm"
                  :state="errors.length > 0 ? false : null"
                  type="password"
                  :placeholder="t('Xác nhận mật khẩu')"
                  autocomplete="off"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
        </b-row>
        <b-row>
          <b-col>
            <select-multiple-apartment
              v-model="itemLocal.apartments"
              :default-value="itemLocal.apartments"
              :label="t('Chọn tòa nhà quản lý')"
              class="select-apartment"
            />
          </b-col>
        </b-row>

        <!-- Active/Inactive -->
        <b-row>
          <b-col
            cols="12"
            class=""
          >
            <b-form-group>
              <b-form-checkbox
                v-model="itemLocal.active"
                name="check-button"
                switch
                inline
              >
                {{ t('Hoạt động') }}
              </b-form-checkbox>
            </b-form-group>
          </b-col>
        </b-row>
        <!-- <b-row
          v-if="itemLocal.role"
          class="mt-1"
        >
          <b-col cols="12">
            <abilities-table
              v-model="itemLocal.abilities"
              :default-value="itemLocal.abilities"
              :editable="false"
            />
          </b-col>
        </b-row> -->

      </b-form>
    </validation-observer>
  </b-modal>
</template>

<script>
import {
  BModal,
  BRow,
  BCol,
  BFormGroup,
  BFormInput,
  BForm,
  BFormCheckbox,
  BAlert,
} from 'bootstrap-vue';
import { ValidationProvider, ValidationObserver } from 'vee-validate';
import { required } from '@validations';
import formValidation from '@core/comp-functions/forms/form-validation';
import SelectRole from '@/views/components/SelectRole.vue';
import SelectMultipleApartment from '@/views/components/SelectMultipleApartment.vue';
import useManagerModal from './useManagerModal';

export default {
  components: {
    BModal,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BForm,
    BFormCheckbox,
    ValidationProvider,
    ValidationObserver,
    BAlert,
    SelectRole,
    SelectMultipleApartment,
  },
  props: {
    item: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      required,
    };
  },
  setup(props, { emit }) {
    const {
      refFormObserver, getValidationState, resetForm, clearForm,
    } = formValidation();

    const {
      refModal,
      itemLocal,
      isSubmitting,
      resetItemLocal,
      resetModal,
      loadData,
      onSubmit,
      isTingTong,
      t,
    } = useManagerModal(props, emit, refFormObserver);

    return {
      refModal,
      itemLocal,
      isSubmitting,
      resetItemLocal,
      resetModal,
      onSubmit,
      loadData,
      isTingTong,
      // Form Validation
      resetForm,
      clearForm,
      refFormObserver,
      getValidationState,
      t,
    };
  },
};
</script>

<style lang="scss" scope>
.select-apartment {
  z-index: 1041 !important;
}
</style>
