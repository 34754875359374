import useJwt from '@/auth/jwt/useJwt';

export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {
    fetchManagers(ctx, params) {
      return useJwt.getManagers(params).then(response => response);
    },
    createManager(ctx, data) {
      return useJwt.createManager(data).then(response => response);
    },
    updateManager(ctx, data) {
      return useJwt.updateManager(data).then(response => response);
    },
    deleteManagers(ctx, data) {
      return useJwt.deleteManagers(data).then(response => response);
    },
    getManagerDetail(ctx, data) {
      return useJwt.getManagerDetail(data).then(response => response);
    },
  },
};
