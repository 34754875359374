import useJwt from '@/auth/jwt/useJwt';

export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {
    fetchRoles(ctx, params) {
      return useJwt.getRoles(params).then(response => response);
    },
    createRole(ctx, data) {
      return useJwt.createRole(data).then(response => response);
    },
    updateRole(ctx, data) {
      return useJwt.updateRole(data).then(response => response);
    },
    deleteRoles(ctx, data) {
      return useJwt.deleteRoles(data).then(response => response);
    },
    getRoleDetail(ctx, id) {
      return useJwt.getRoleDetail(id).then(response => response);
    },
    getRoleAbilities(ctx, id) {
      return useJwt.getRoleAbilities(id).then(response => response);
    },
    getAllPermissions(ctx, id) {
      return useJwt.getPermissionGroups(id).then(response => response);
    },
  },
};
